@font-face {
  font-family: "Roboto";
  src: url(assets/Roboto-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #242424;
  font-family: "Roboto", Arial, sans-serif;
}

:root {
  --blob-speed: 10s;
  --blob-speed-scale: calc(0.7 * var(--blob-speed));
  --blob-speed-move: calc(0.5 * var(--blob-speed));
  --blob-speed-rotate: calc(5 * var(--blob-speed));
  --blob-size: 500px;
  --blob-height-1: calc(0.9 * var(--blob-size));
  --blob-height-2: calc(0.9 * var(--blob-size));
  --blob-height-3: calc(0.9 * var(--blob-size));
  --blob-opacity: 0.4;
  --blob-blur: 80px;
  --blob-color-1: #d301a2;
  --blob-color-2: #01dfca;
  --blob-color-3: #0181e4;
}

.blobs {
  position: fixed;
  height: 60%;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%); /* Center the blobs horizontally */
  filter: blur(var(--blob-blur));
  overflow: hidden;
  z-index: -1;
}

.blobs::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    hsla(0deg, 0%, 72%, 0.08),
    transparent
  );
  backdrop-filter: blur(8rem) saturate(1.6);
  border-radius: 1.5rem;
  z-index: 2;
}

.blob {
  position: absolute; /* Added */
  top: 50%; /* Added */
  transform: translate(-50%, -50%); /* Added */
  width: 200%;
  height: var(--blob-height-1);
  background-color: var(--blob-color-1);
  border-radius: 50%;
  opacity: var(--blob-opacity);
  mix-blend-mode: multiply;
  animation: blob ease-in-out var(--blob-speed-scale) infinite;
}

.blob:nth-child(2) {
  width: 300%;
  height: var(--blob-height-2);
  background-color: var(--blob-color-2);
  animation-delay: calc(-0.6 * var(--blob-speed-scale));
}

.blob:nth-child(3) {
  width: 400%;
  height: var(--blob-height-3);
  background-color: var(--blob-color-3);
  animation-delay: calc(-0.2 * var(--blob-speed-scale));
}

@keyframes blob {
  0%,
  100% {
    transform: scale(0.8, 2);
  }
  50% {
    transform: scale(1.4, 0.8);
  }
}

.blob-rotate {
  position: absolute;
  left: 50%;
  top: 50%;
  animation: blob-rotate linear var(--blob-speed-rotate) infinite alternate;
}

.blob-rotate:nth-child(2) {
  animation-duration: calc(2 * var(--blob-speed-rotate));
  animation-delay: calc(-1.5 * var(--blob-speed-rotate));
}

.blob-rotate:nth-child(3) {
  animation-direction: alternate-reverse;
  animation-duration: calc(0.8 * var(--blob-speed-rotate));
  animation-delay: calc(-1 * var(--blob-speed-rotate));
}

@keyframes blob-rotate {
  0% {
    transform: translate3d(-50%, -50%, 0) rotateZ(-28deg);
    transform-origin: 50% 100%;
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotateZ(28deg);
    transform-origin: 50% 0%;
  }
}

.blob-move {
  animation: blob-move ease-in-out var(--blob-speed-move) infinite;
}

.blobs > :nth-child(2) .blob-move {
  animation-delay: calc(-0.8 * var(--blob-speed-move));
}

.blobs > :nth-child(3) .blob-move {
  animation-delay: calc(-0.4 * var(--blob-speed-move));
}

@keyframes blob-move {
  0%,
  100% {
    transform: translateX(30%);
  }
  50% {
    transform: translateX(-30%);
  }
}
