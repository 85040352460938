.landing {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.landing-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.landing-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.landing-title-containter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.landing-title-containter-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.landing-title {
  font-size: 50px;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.landing-title-bold {
  font-weight: bold;
  font-size: 60px;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.landing-title-special-bold {
  font-weight: bold;
  font-size: 60px;
  color: #0181e4;
  text-align: center;
  z-index: 2;
  animation: colorize ease-in-out 10s infinite;
}

@keyframes colorize {
  0% {
    color: #0181e4;
  }
  25% {
    color: #d301a2;
  }
  50% {
    color: #01dfca;
  }
  75% {
    color: #d301a2;
  }
  100% {
    color: #0181e4;
  }
}

.landing-subtitle {
  width: 310px;
  font-size: 20px;
  color: #e4e4e4;
  text-align: center;
  z-index: 2;
}

.landing-subtitle-bold {
  font-size: 23px;
  font-weight: bold;
  color: #b5b5b5;
  z-index: 2;
  text-align: left;
  margin-left: 5px;
}

.landing-buttons {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  color: #fff;
  gap: 25px;
}

.landing-buttons a {
  width: 60px;
  height: 60px;
  background: linear-gradient(to right, #242424, #4b003a87);
  box-shadow: inset 0 0 10px #00fd91, inset 2px 0 10px #d301a2,
    inset -4px 0 8px #0ff, inset 4px 0 30px #d301a2, inset -4px 0 30px #0181e4,
    0 0 5px #00fd91, -2px 0 8px #d301a2, 2px 0 8px #01dfca;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  transition: font-size 0.5s, box-shadow 0.5s;
  color: #fff;
}

.landing-buttons a:hover {
  box-shadow: inset 0 0 50px #00fd91, inset 2px 0 100px #00fd91,
    inset -4px 0 40px #00fd91, inset 4px 0 30px #00fd91,
    inset -4px 0 75px #00fd91, 0 0 30px #00fd91, -2px 0 40px #00fd91,
    2px 0 40px #00fd91;
  font-size: 20px;
}

.landing-special-button {
  width: 100%;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bold;
}

.landing-special-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #00fd91,
    #d301a2,
    #01dfca,
    #0181e4,
    #d301a2,
    #00fd91
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.landing-special-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #242424;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.landing-special-button:hover {
  color: #000;
  transition: box-shadow 1s;
  box-shadow: inset 0 0 50px #00fd91, inset 2px 0 100px #d301a2,
    inset -4px 0 040px #0ff, inset 4px 0 300px #d301a2,
    inset -4px 0 150px #0181e4, 0 0 50px #00fd91, -2px 0 80px #d301a2,
    2px 0 80px #01dfca;
}

.landing-special-button:hover:after {
  transition: background 1s;
  background: #00fd91;
}

.landing-button {
  width: 30%;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: #fff;
}

.landing-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #242424;
  left: 0;
  top: 0;
  border-radius: 10px;
  border: solid 1px #0181e4;
}

.landing-button:hover::after {
  background: #767676;
}

.landing-logo {
  height: 300px;
  width: 300px;
}

.landing-logo img {
  animation: superGlow ease-in-out 3s infinite;
}

@keyframes superGlow {
  0% {
    filter: drop-shadow(0 0 10px rgba(255, 0, 234, 0.7));
    scale: 1;
  }

  50% {
    filter: drop-shadow(0 0 50px #0181e4);
    scale: 1.05;
  }

  100% {
    filter: drop-shadow(0 0 10px rgba(255, 0, 234, 0.7));
    scale: 1;
  }
}

@media screen and (max-width: 430px) {
  .landing-title-containter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }

  .landing-logo {
    height: 200px;
    width: 200px;
    margin-bottom: 25px;
  }

  .landing-title {
    font-size: 30px;
    color: #fff;
    text-align: center;
    z-index: 2;
  }

  .landing-subtitle{
     font-size: 15px;
  }

  .landing-title-containter-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }

  .landing-title-bold {
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    text-align: center;
    z-index: 2;
  }

  .landing-title-special-bold {
    font-weight: bold;
    font-size: 40px;
    color: #0181e4;
    text-align: center;
    z-index: 2;
    animation: colorize ease-in-out 10s infinite;
  }
}
